
import { defineComponent } from 'vue';
import Accordion from "primevue/accordion";

export default defineComponent({
  name: 'CustomAccordion',
  props: {
    activeIndex: {
      default: [0]
    }
  },
  components: {
    Accordion,
  }
})
